@import "../../styles/base.scss";

.sign-in {
  &-panel {
    @extend %selection-panel;
  }

  &-field {
    div[class^="InputGroup-label"] {
      font-family: $font-lato !important;
      font-size: 12px !important;
      color: $gray40 !important;
      font-weight: normal !important;
    }
  }
}

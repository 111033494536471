div:focus, button:focus {
  outline: none !important; }

.language-code {
  background-color: #F6F6F6;
  border: 1px solid #e1e1e1;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 11.5px;
  margin-left: 6px; }

.image-name-id {
  display: inline-flex; }
  .image-name-id > div, .image-name-id img {
    display: inline-block;
    margin-right: 10px;
    align-self: center; }

.name-id div:first-child a {
  text-decoration: none;
  color: #079BB3; }

.name-id div:last-child {
  font-size: 12px;
  color: #999999;
  margin-top: 3px; }

.select {
  min-width: 190px; }

[role="dialog"] {
  padding: 0 !important;
  max-width: fit-content !important; }
  [role="dialog"] .table .rt-thead {
    overflow: hidden scroll; }
    [role="dialog"] .table .rt-thead::-webkit-scrollbar {
      background: transparent; }
  [role="dialog"] .rt-tbody {
    overflow-y: scroll; }

.main {
  display: flex;
  flex-grow: 1;
  width: 100%; }
  .main .content {
    flex-grow: 1;
    overflow-y: auto; }

.detail-main {
  display: flex;
  justify-content: flex-end; }
  .detail-main .detail-content {
    margin-right: 280px;
    padding: 53px 22px 53px 53px;
    width: 100%; }
    .detail-main .detail-content.new {
      margin-right: 652px; }
    .detail-main .detail-content.full-width {
      margin-right: 0;
      padding: 53px; }

.select.gray {
  background-color: #eeeeee;
  margin-left: auto !important;
  border: none;
  border-radius: 3px; }

.side-menu {
  min-width: 240px;
  width: 240px;
  max-width: 240px;
  height: auto;
  background-color: #333333;
  overflow-x: hidden;
  position: relative; }
  .side-menu-item {
    border-left: 6px solid #333333;
    height: 46px;
    display: flex;
    cursor: pointer; }
    .side-menu-item a {
      text-decoration: none;
      width: 100%; }
      .side-menu-item a:visited, .side-menu-item a:active, .side-menu-item a:focus {
        color: white; }
    .side-menu-item div, .side-menu-item > a {
      display: flex;
      align-items: center; }
    .side-menu-item.selected {
      background-color: black;
      border-color: #079BB3; }
    .side-menu-item-icon {
      margin-left: 16px; }
      .side-menu-item-icon img {
        height: 30px;
        width: 30px; }
    .side-menu-item-text {
      margin-left: 10px;
      color: white;
      font-family: "Lato", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px; }
  .side-menu .version-number {
    color: white;
    position: absolute;
    bottom: 0;
    margin-left: 2.5rem;
    margin-bottom: 2rem; }

div:focus, button:focus {
  outline: none !important; }

.language-code {
  background-color: #F6F6F6;
  border: 1px solid #e1e1e1;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 11.5px;
  margin-left: 6px; }

.image-name-id {
  display: inline-flex; }
  .image-name-id > div, .image-name-id img {
    display: inline-block;
    margin-right: 10px;
    align-self: center; }

.name-id div:first-child a {
  text-decoration: none;
  color: #079BB3; }

.name-id div:last-child {
  font-size: 12px;
  color: #999999;
  margin-top: 3px; }

.select {
  min-width: 190px; }

[role="dialog"] {
  padding: 0 !important;
  max-width: fit-content !important; }
  [role="dialog"] .table .rt-thead {
    overflow: hidden scroll; }
    [role="dialog"] .table .rt-thead::-webkit-scrollbar {
      background: transparent; }
  [role="dialog"] .rt-tbody {
    overflow-y: scroll; }

.main {
  display: flex;
  flex-grow: 1;
  width: 100%; }
  .main .content {
    flex-grow: 1;
    overflow-y: auto; }

.detail-main {
  display: flex;
  justify-content: flex-end; }
  .detail-main .detail-content {
    margin-right: 280px;
    padding: 53px 22px 53px 53px;
    width: 100%; }
    .detail-main .detail-content.new {
      margin-right: 652px; }
    .detail-main .detail-content.full-width {
      margin-right: 0;
      padding: 53px; }

.select.gray {
  background-color: #eeeeee;
  margin-left: auto !important;
  border: none;
  border-radius: 3px; }

.table.ReactTable .rt-thead.-header .rt-th.-sort-asc div:first-of-type::after, .table.ReactTable .rt-thead.-header .rt-th.-sort-desc div:first-of-type::after {
  pointer-events: none;
  position: relative;
  top: -1px;
  left: 2px;
  color: #808080;
  font-size: 9px; }

.table.ReactTable {
  border-top: none; }
  .table.ReactTable.full-width {
    border: none; }
  .table.ReactTable.dragging {
    user-select: none; }
  .table.ReactTable .rt-noData {
    display: none; }
  .table.ReactTable .rt-thead.-header {
    height: 40px;
    background-color: #f4f4f4;
    box-shadow: none;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC; }
    .table.ReactTable .rt-thead.-header .rt-th {
      padding: 0 16px;
      height: min-content;
      align-self: center;
      border-right: none; }
      .table.ReactTable .rt-thead.-header .rt-th > div:not([class="rt-resizer"]) {
        text-align: left;
        font-family: "Lato", Helvetica, Arial, sans-serif;
        font-size: 15px;
        color: #666666; }
      .table.ReactTable .rt-thead.-header .rt-th.-sort-asc {
        box-shadow: none; }
        .table.ReactTable .rt-thead.-header .rt-th.-sort-asc div:first-of-type::after {
          content: "\25BC"; }
      .table.ReactTable .rt-thead.-header .rt-th.-sort-desc {
        box-shadow: none; }
        .table.ReactTable .rt-thead.-header .rt-th.-sort-desc div:first-of-type::after {
          content: "\25b2"; }
  .table.ReactTable .rt-tbody .rt-tr-group {
    height: 69px;
    border-bottom: 1px solid #DDDDDD;
    background-color: white; }
    .table.ReactTable .rt-tbody .rt-tr-group .rt-td {
      align-self: center;
      font-family: "Lato", Helvetica, Arial, sans-serif;
      font-size: 15px;
      color: #444444;
      border-right: none;
      padding: 0 16px;
      display: flex; }
      .table.ReactTable .rt-tbody .rt-tr-group .rt-td .table-image {
        height: 44px; }
        .table.ReactTable .rt-tbody .rt-tr-group .rt-td .table-image.placeholder {
          background-image: url(../../icons/placeholder.jpg);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 78px; }
  .table.ReactTable .pagination-bottom {
    margin: none; }

.table.editable-list .rt-thead {
  margin-bottom: 10px; }

.table.editable-list .rt-tbody .rt-tr-group {
  border: 1px solid #999999 !important;
  background-color: #FAFAFA;
  border-collapse: collapse;
  height: 46px; }
  .table.editable-list .rt-tbody .rt-tr-group .rt-td .table-image {
    height: 38px; }

.table.editable-list .rt-tbody .rt-tr-group:not(:last-child) {
  margin-bottom: 10px; }

.table.editable-list .edit-buttons {
  text-align: right; }
  .table.editable-list .edit-buttons > div {
    display: inline-block;
    cursor: pointer; }
    .table.editable-list .edit-buttons > div:last-child {
      margin-left: 15px; }

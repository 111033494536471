%hidden {
  visibility: none;
  height: 0;
}

.preview-player {
  &.valid {
    img {
      @extend %hidden;
    }
  }
  &.invalid {
    .video-js {
      @extend %hidden;
    }
  }

  .video-js {
    height: 350px;
    width: 622px;
  }
}

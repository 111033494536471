div:focus, button:focus {
  outline: none !important; }

.language-code {
  background-color: #F6F6F6;
  border: 1px solid #e1e1e1;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 11.5px;
  margin-left: 6px; }

.image-name-id {
  display: inline-flex; }
  .image-name-id > div, .image-name-id img {
    display: inline-block;
    margin-right: 10px;
    align-self: center; }

.name-id div:first-child a {
  text-decoration: none;
  color: #079BB3; }

.name-id div:last-child {
  font-size: 12px;
  color: #999999;
  margin-top: 3px; }

.select {
  min-width: 190px; }

[role="dialog"] {
  padding: 0 !important;
  max-width: fit-content !important; }
  [role="dialog"] .table .rt-thead {
    overflow: hidden scroll; }
    [role="dialog"] .table .rt-thead::-webkit-scrollbar {
      background: transparent; }
  [role="dialog"] .rt-tbody {
    overflow-y: scroll; }

.main {
  display: flex;
  flex-grow: 1;
  width: 100%; }
  .main .content {
    flex-grow: 1;
    overflow-y: auto; }

.detail-main {
  display: flex;
  justify-content: flex-end; }
  .detail-main .detail-content {
    margin-right: 280px;
    padding: 53px 22px 53px 53px;
    width: 100%; }
    .detail-main .detail-content.new {
      margin-right: 652px; }
    .detail-main .detail-content.full-width {
      margin-right: 0;
      padding: 53px; }

.select.gray {
  background-color: #eeeeee;
  margin-left: auto !important;
  border: none;
  border-radius: 3px; }

.edit-group {
  font-family: "Lato", Helvetica, Arial, sans-serif; }
  .edit-group-label {
    font-size: 12px;
    color: #999999; }
    .edit-group-label.warning {
      color: #BA2453; }
    .edit-group-label.required::after {
      content: "*"; }
  .edit-group-text {
    font-size: 15px;
    color: #444444;
    word-break: break-word; }
    .edit-group-text.json {
      overflow-x: scroll; }
  .edit-group-image {
    margin-top: 3px;
    max-width: 100%;
    max-height: 350px; }
  .edit-group-control {
    margin-top: 5px; }
    .edit-group-control > input, .edit-group-control textarea, .edit-group-control .tag-list-input {
      max-width: 700px; }

.stepper {
  &, > div {
    display: inline-block;
  }

  &-minus, &-plus {
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }

  &-minus {
    margin-right: 5px;
  }

  &-plus {
    margin-left: 5px;
  }

  &-input {
    width: 58px;
    margin-bottom: 0;
  }
}

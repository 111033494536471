.columns {
  > div {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .column {
    > div {
      margin-bottom: 17px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.draggable {
  &-original {
    height: 0 !important;
    display: none !important;
  }

  &-clone {
    position: absolute;
    z-index: 5000;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
    user-select: none;
  }

  &-filler {
      opacity: 0.3;
  }
}

// Colors
$black: black;
$white: white;
$off-white: #FAFAFA;
$gray5: #F6F6F6;
$gray10: #f4f4f4;
$gray15: #e1e1e1;
$gray17_5: #eeeeee;
$gray20: #DDDDDD;
$gray30: #CCCCCC;
$gray35: #C7C7C7;
$gray40: #999999;
$gray50: #888888;
$gray55: #808080;
$gray60: #666666;
$gray70: #484848;
$gray80: #444444;
$gray90: #333333;
$teal: #079BB3;
$magenta: #BA2453;
$pink: #D32781;
$green: #A2B00C;
$yellow: #FDE86A;
$orange: #EF6C2A;
$baby-blue1: #EFFBFF;
$baby-blue2: #DCF3FB;
$blue: #90b4d8;

// Fonts
$font-titilium: 'Titillium Web', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-lato:  'Lato', Helvetica, Arial, sans-serif;

// Constants/Fragments
$header-height: 90px;
$outer-margin: 22px;
$details-outer-margin: 53px;
$modal-controls-height: 60px;
$modal-actions-height: 56px;
$max-edit-image-height: 350px;

%selection-panel {
  width: 576px;
  margin: 53px;
}

// Mixins
@mixin all-heights($value) {
  min-height: $value;
  height: $value;
  max-height: $value;
}

@mixin all-widths($value) {
  min-width: $value;
  width: $value;
  max-width: $value;
}

@mixin equal-dimensions($value) {
  height: $value;
  width: $value;
}

// Global Styles
div:focus, button:focus {
  outline: none !important;
}

.language-code {
  background-color: $gray5;
  border: 1px solid $gray15;
  font-family: $font-lato;
  font-size: 11.5px;
  margin-left: 6px;
}

.image-name-id {
  display: inline-flex;

  > div, img {
    display: inline-block;
    margin-right: 10px;
    align-self: center;
  }
}

.name-id {
  div:first-child {
    a {
      text-decoration: none;
      color: $teal;
    }
  }

  div:last-child {
    font-size: 12px;
    color: $gray40;
    margin-top: 3px;
  }
}

.select {
  min-width: 190px;
}

[role="dialog"] {
  padding: 0 !important;
  max-width: fit-content !important;

  // This hack is because modal tables can have the body scroll, but the header fixed, which causes the header
  // and body to lose alignment because of the scrollbar. This has the scrollbar, but makes it transparent so that
  // they're properly aligned. This only works in Webkit browswers.
  .table .rt-thead {
    overflow: hidden scroll;

    &::-webkit-scrollbar {
        background: transparent;
    }
  }

  .rt-tbody {
    overflow-y: scroll;
  }
}

// Styling for content list and detail screens
.main {
  display: flex;
  flex-grow: 1;
  width: 100%;

  .content {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.detail-main {
  display: flex;
  justify-content: flex-end;

  .detail-content {
    margin-right: 280px;
    padding: $details-outer-margin 22px $details-outer-margin $details-outer-margin;
    width: 100%;

    &.new {
      margin-right: 652px;
    }

    &.full-width {
      margin-right: 0;
      padding: $details-outer-margin;
    }
  }
}

.select.gray {
  background-color: $gray17_5;
  margin-left: auto !important;
  border: none;
  border-radius: 3px;
}

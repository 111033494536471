div:focus, button:focus {
  outline: none !important; }

.language-code {
  background-color: #F6F6F6;
  border: 1px solid #e1e1e1;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 11.5px;
  margin-left: 6px; }

.image-name-id {
  display: inline-flex; }
  .image-name-id > div, .image-name-id img {
    display: inline-block;
    margin-right: 10px;
    align-self: center; }

.name-id div:first-child a {
  text-decoration: none;
  color: #079BB3; }

.name-id div:last-child {
  font-size: 12px;
  color: #999999;
  margin-top: 3px; }

.select {
  min-width: 190px; }

[role="dialog"] {
  padding: 0 !important;
  max-width: fit-content !important; }
  [role="dialog"] .table .rt-thead {
    overflow: hidden scroll; }
    [role="dialog"] .table .rt-thead::-webkit-scrollbar {
      background: transparent; }
  [role="dialog"] .rt-tbody {
    overflow-y: scroll; }

.main {
  display: flex;
  flex-grow: 1;
  width: 100%; }
  .main .content {
    flex-grow: 1;
    overflow-y: auto; }

.detail-main {
  display: flex;
  justify-content: flex-end; }
  .detail-main .detail-content {
    margin-right: 280px;
    padding: 53px 22px 53px 53px;
    width: 100%; }
    .detail-main .detail-content.new {
      margin-right: 652px; }
    .detail-main .detail-content.full-width {
      margin-right: 0;
      padding: 53px; }

.select.gray {
  background-color: #eeeeee;
  margin-left: auto !important;
  border: none;
  border-radius: 3px; }

.header {
  background-color: #333333;
  display: inline-flex;
  min-height: 90px;
  height: 90px;
  max-height: 90px; }
  .header > div {
    display: flex;
    align-items: center; }
  .header-logo {
    margin-left: 22px; }
    .header-logo img {
      height: 61px; }
  .header-catalog {
    margin-left: 100px;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    color: #90b4d8; }
  .header-tabs {
    text-transform: uppercase;
    margin-left: 20px; }
    .header-tabs [role="tablist"] {
      margin-bottom: 0; }
    .header-tabs [class^="Tabs-spacer"] {
      display: none; }
  .header-tab {
    margin-right: 14px;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px; }
    .header-tab[aria-selected="false"] {
      border-bottom: none;
      color: white; }
    .no-selection .header-tab {
      border: none;
      color: white;
      cursor: pointer; }
  .header-menus {
    margin: 0 22px 0 auto; }
    .header-menus [role="menu"] {
      right: 0; }
      .header-menus [role="menu"] ul {
        border: 1px solid black; }
  .header-menu-button {
    background-color: #333333 !important;
    border-color: #333333 !important;
    font-family: "Lato", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: white !important;
    padding: 0 !important; }
  .header-admin-menu {
    margin-right: 30px; }
  .header-user-menu [role="menuitem"].selected {
    color: #90b4d8;
    pointer-events: none; }
  .header-user-menu [role="menuitem"]:first-child {
    border-top: none !important; }
  .header-user-menu [role="menuitem"]:last-child {
    border-top: 1px solid white; }
  .header-user-menu.no-catalog ul, .header-user-menu.no-catalog [class*="caret"] {
    display: none; }
